import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ReactElement } from 'react';

/**
 * Expectation is that there is a title that spans a row
 * up to 3 subtitles that span a row
 * first data of a row is a label
 * @param props
 * @returns
 */
export function NutritionPanelRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    title: string;
    subTitle1?: string;
    subTitle2?: string;
    subTitle3?: string;
    content: {
      rows: {
        cells: string[];
      }[];
    };
  }>
): ReactElement {
  const cols = props.value.content.rows.reduce((acc, row) => {
    if (row.cells.length > acc) {
      acc = row.cells.length;
    }
    return acc;
  }, 0);

  return (
    (<table className="mx-0 mb-4 mt-0 inline-table h-fit border-collapse border-spacing-0 border border-solid border-grey-dark align-top text-sm">
      <thead className="border-none bg-grey-dark text-xl uppercase text-white dark:bg-grey-mid dark:text-grey-darkest">
        <tr>
          <th colSpan={cols}>{props.value.title}</th>
        </tr>
      </thead>
      <tbody>
        {(props.value.subTitle1 ||
          props.value.subTitle2 ||
          props.value.subTitle3) && (
            <tr className="bg-grey-dark text-center text-white">
              <td
                colSpan={cols}
                className="bg-grey-mid px-1 py-0.5 text-center text-grey-darkest dark:bg-grey-dark dark:text-white"
              >
                <span className="pr-5 md:pr-10">{props.value.subTitle1}</span>
                <span>{props.value.subTitle2}</span>
                <span>{props.value.subTitle3}</span>
              </td>
            </tr>
          )}
        {props.value.content.rows.map((row, rowidx) => (
          <tr
            key={`tr-${rowidx}`}
            data-cols={cols}
            className='grid data-[cols=3]:grid-cols-3 data-[cols=2]:grid-cols-2 data-[cols=1]:grid-cols-1 bg-white 
            text-black even:bg-grey-light 
            hover:bg-orange hover:text-white dark:bg-black
            dark:text-white dark:even:bg-grey-darkest dark:hover:bg-orange'
          >
            {row.cells.length === 2 && !!props.value.content.rows[rowidx + 1] && props.value.content.rows[rowidx + 1].cells.length === 3 && <td></td>}
            {row.cells.map((cell, cellidx) => (
              <td
                key={`td-${cellidx}`}
                className="px-5 py-[1px] text-center first:min-w-[160px] first:text-left"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>)
  );
}
