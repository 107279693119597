import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import {
  Children,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useSwipeable } from 'react-swipeable';

import { useWindowSize } from '@hooks/useWindowSize';
import { mergeStyles } from '@lib/styles';

type Props = {
  children: ReactNode[];
  testId?: string;
  itemsPerView: number;
  naturalWidth?: number;
  naturalHeight?: number;
  infinite?: boolean;
  isIntrinsicHeight?: boolean;
} & Record<string, any>;

export function Carousel({
  children,
  testId = 'carousel',
  itemsPerView,
  naturalWidth = 100,
  naturalHeight = 100,
  infinite = true,
  isIntrinsicHeight = false,
  ...props
}: Props): ReactElement {
  return (
    <div {...props}>
      <CarouselProvider
        naturalSlideWidth={naturalWidth}
        naturalSlideHeight={naturalHeight}
        totalSlides={Children.count(children)}
        visibleSlides={itemsPerView}
        infinite={infinite}
        isPlaying={false}
        isIntrinsicHeight={isIntrinsicHeight}
        interval={3000}
        className="relative mx-auto max-w-[98vw]"
      >
        <Slider
          className="mx-auto w-[calc(98vw_-_120px)]"
          classNameTray="gap-4"
        >
          {children.map((child, idx) => (
            <Slide index={idx} key={idx} className="mx-2.5 my-0">
              {child}
            </Slide>
          ))}
        </Slider>
        <ButtonBack
          tabIndex={-1}
          data-testid={`${testId}-prev`}
          className="carousel-navigation absolute bottom-0 top-0 my-auto flex h-12.5 w-12.5
          items-center justify-center rounded-full border border-solid border-grey-mid bg-white text-black 
          opacity-70 transition-opacity duration-100 ease-in hover:opacity-100
          dark:bg-black dark:text-white"
          style={{
            left: 0,
          }}
        >
          <FiChevronLeft size={20} />
        </ButtonBack>
        <ButtonNext
          tabIndex={-1}
          data-testid={`${testId}-next`}
          className="carousel-navigation absolute bottom-0 top-0 my-auto flex h-12.5 w-12.5
          items-center justify-center rounded-full border border-solid border-grey-mid bg-white text-black 
          opacity-70 transition-opacity duration-100 ease-in hover:opacity-100
          dark:bg-black dark:text-white"
          style={{
            right: 0,
          }}
        >
          <FiChevronRight size={20} />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
}

type BulkProps = {
  children: ReactNode[];
  testId?: string;
  className?: string;
  fadeTo?: string;
};

export function BulkCarousel({
  children,
  testId = 'carousel',
  className,
  fadeTo,
}: BulkProps): ReactElement {
  const firstSlideRef = useRef<HTMLDivElement>(null);
  const lastSlideRef = useRef<HTMLDivElement>(null);
  const slideContainerRef = useRef<HTMLDivElement>(null);
  const slideTranslate = useRef(0);

  const [slideWidth, setSlideWidth] = useState(0);
  const [wrapperRect, setWrapperRect] = useState({ l: 0, r: 0, w: 0 });
  const [showArrows, setShowArrows] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    const wrapper =
      slideContainerRef.current?.parentElement?.getBoundingClientRect();

    if (lastSlideRef.current) {
      const style = window.getComputedStyle(lastSlideRef.current);
      const offsetWidth = lastSlideRef.current.offsetWidth;
      const slideMargin =
        parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      const slidePadding =
        parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
      const slideBorder =
        parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
      const slideTotalWidth =
        offsetWidth + slideMargin + slidePadding + slideBorder;
      setSlideWidth(slideTotalWidth);

      // check if arrows should be visible or not, based on first and last ref being in bounds
      if (wrapper && firstSlideRef.current) {
        const lastSlideIsOutOfView =
          wrapper.right < lastSlideRef.current.getBoundingClientRect()?.right;
        const firstSlideIsOutOfView =
          firstSlideRef.current.getBoundingClientRect()?.right < 16;
        const translationNeeded =
          wrapper.right - lastSlideRef.current.getBoundingClientRect()?.right >
          slideWidth;

        // arrows should always show if first or last slide is out of window bounds
        setShowArrows((lastSlideIsOutOfView || firstSlideIsOutOfView) ?? true);

        if (
          !lastSlideIsOutOfView &&
          translationNeeded &&
          firstSlideIsOutOfView
        ) {
          /* This protects against the following use case:
              A user using a small window, clicking to the end, and then expanding browser width.
              If a user has clicked to the last slide in a small browser window, if the window expands, 
              we want all possible slides to be visible and not remain hidden if there is space.
          */
          clickLeft();
        }
      }
    }

    setWrapperRect({
      l: wrapper?.left ?? 0,
      r: wrapper?.right ?? 0,
      w: wrapper?.width ?? 0,
    });
  }, [width]);

  const clickLeft = () => {
    // Adjust translation
    slideTranslate.current = slideTranslate.current + slideWidth;
    const slide = firstSlideRef.current?.getBoundingClientRect();
    if (slideContainerRef.current) {
      if (
        slide &&
        wrapperRect.l < slide.left &&
        slideContainerRef.current.parentElement
      ) {
        // Go back to the end
        slideTranslate.current = -(
          slideContainerRef.current.offsetWidth -
          slideContainerRef.current.parentElement?.offsetWidth
        );
      }
      slideContainerRef.current.style.transform = `translateX(${slideTranslate.current}px)`;
    }
  };

  const clickRight = () => {
    // Adjust translation
    slideTranslate.current = slideTranslate.current - slideWidth;
    // Check if we're at the end and return to start if so
    const slide = lastSlideRef.current?.getBoundingClientRect();
    if (slideContainerRef.current) {
      if (slide && wrapperRect.r > slide.right) {
        // Go back to the start
        slideTranslate.current = 0;
      }
      slideContainerRef.current.style.transform = `translateX(${slideTranslate.current}px)`;
    }
  };

  const handleFocus = (e) => {
    const slide = e.target.getBoundingClientRect();
    if (slideContainerRef.current) {
      if (slide && wrapperRect.l < slide.left && wrapperRect.r > slide.right) {
        // We're in the middle, do nothing
        return;
      }
      if (slide && wrapperRect.l > slide.left) {
        // We're on the left, slide one to the right
        clickLeft();
      }
      if (slide && wrapperRect.r < slide.right) {
        // We're on the right, slide one to the left
        clickRight();
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => clickRight(),
    onSwipedRight: (eventData) => clickLeft(),
  });

  return (
    <section
      aria-label="carousel"
      className={mergeStyles(
        className ?? '',
        'carousel relative mx-4 overflow-clip'
      )}
    >
      <div {...swipeHandlers}>
        <div
          className="flex w-max items-stretch transition-all duration-200"
          ref={slideContainerRef}
        >
          {children.map((child, idx) => (
            <div
              key={idx}
              role="group"
              aria-roledescription="slide"
              aria-label={`slide ${idx} of ${children.length}`}
              className="mx-2"
              id={`slide-${idx}`}
              onFocus={handleFocus}
              ref={
                idx === children.length - 1
                  ? lastSlideRef
                  : idx === 0
                    ? firstSlideRef
                    : undefined
              }
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      <div
        className={mergeStyles(
          fadeTo ?? '',
          'pointer-events-none absolute right-0 top-0 h-full w-[10%] bg-gradient-to-r from-transparent from-0% to-orange to-100%'
        )}
      />
      {showArrows && (
        <div className="hidden md:block">
          <button
            onClick={clickLeft}
            className="absolute bottom-0 left-4 top-0 my-auto flex h-12 w-12 items-center justify-center rounded-full border border-grey-mid bg-grey-light text-grey-dark"
            tabIndex={-1}
            data-testid={`${testId}-prev`}
          >
            <FiChevronLeft size={20} />
          </button>
          <button
            onClick={clickRight}
            className="absolute bottom-0 right-4 top-0 my-auto flex h-12 w-12 items-center justify-center rounded-full border border-grey-mid bg-grey-light text-grey-dark"
            tabIndex={-1}
            data-testid={`${testId}-next`}
          >
            <FiChevronRight size={20} />
          </button>
        </div>
      )}
    </section>
  );
}
