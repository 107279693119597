/* eslint-disable react/jsx-one-expression-per-line */
import {
  PortableText,
  PortableTextMarkComponentProps,
  PortableTextTypeComponentProps,
} from '@portabletext/react';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';

import { Accordion } from '@components/Accordion';
import { Aside } from '@components/Aside/Aside';
import { SocialLinks } from '@components/Social';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { FAQ, Recipe } from '@interfaces/Sanity';
import { mergeDeep } from '@lib/deepMerge';
import { mergeStyles } from '@lib/styles';
import { log, logBreadcrumb } from '@lib/utils';

import { CarouselRenderer } from './Carousel';
import { CustomFormRenderer, ExternalFormRenderer } from './Form';
import { ImageRenderer } from './Image';
import { ImageSetRenderer } from './ImageSet';
import { InternalLinkRenderer, LinkRenderer, OnPageLinkRenderer } from './Link';
import { BulletListRenderer, NumberedListRenderer } from './List';
import { NutritionPanelRenderer } from './NutritionPanel';
import { RecipeRenderer } from './Recipe';
import { RecommendedProductRenderer } from './RecommendedProduct';
import { TableRenderer } from './Table';
import { VimeoRenderer } from './Vimeo';
import { YotpoGalleryRenderer } from './YotpoGallery';
import { YotpoReviewCarouselRenderer } from './YotpoReviewCarousel';

import type { PortableTextBlock } from '@portabletext/types';

function AsideRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    content: PortableTextBlock[];
  }>
): ReactElement {
  return (
    <Aside>
      <PortableTextBlockRenderer content={props.value.content} />
    </Aside>
  );
}
function MessageAsideRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    content: PortableTextBlock[];
  }>
): ReactElement {
  return (
    <aside className="clear-both mx-0 my-7 border border-solid border-orange bg-orange-light px-2.5 pb-0 pt-2.5 text-grey-dark dark:bg-orange-dark dark:text-white">
      <PortableTextBlockRenderer content={props.value.content} />
    </aside>
  );
}
function WarningAsideRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    content: PortableTextBlock[];
  }>
): ReactElement {
  return (
    <aside className="clear-both mx-0 my-7 border border-solid border-white bg-grey-dark px-2.5 pb-0 pt-2.5 text-grey-light shadow-[0_0_2px_1px_theme(colors.orange.DEFAULT)] dark:bg-grey-darkest">
      <PortableTextBlockRenderer content={props.value.content} />
    </aside>
  );
}

function ColumnsRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    colNumber: number;
    content: {
      contentBody: PortableTextBlock[];
    }[];
  }>
): ReactElement {
  return (
    <div
      style={{ '--desired-columns': props.value.colNumber } as CSSProperties}
      className="
        grid grid-cols-[repeat(var(--columns),1fr)] [--columns:1] 
        sm:[--columns:max(2,calc(--desired-columns_-_1))] md:[--columns:--desired-columns]
      "
    >
      {props.value.content.map((content, idx) => (
        <div key={idx}>
          <PortableTextBlockRenderer content={content.contentBody} />
        </div>
      ))}
    </div>
  );
}

function FaqRenderer(props: PortableTextTypeComponentProps<FAQ>): ReactElement {
  return (
    <Accordion
      defaultOpen={!props.value.collapsedByDefault}
      items={[
        {
          title: (
            <h3 className="py-1 text-left text-lg font-bold before:text-lg before:content-['Q:_']">
              {props.value.question}
            </h3>
          ),
          content: <PortableTextBlockRenderer content={props.value.answer} />,
        },
      ]}
      wrapperClass="faq my-2"
      itemClass="accordion-item text-[1.1rem] bg-grey-light dark:bg-grey-dark px-2"
      iconSize="20"
    />
  );
}

const SubscriptRenderer = (props: PortableTextMarkComponentProps) => (
  <sub>{props.children}</sub>
);

const SuperscriptRenderer = (props: PortableTextMarkComponentProps) => (
  <sup>{props.children}</sup>
);

const CenteredRenderer = (props: PortableTextMarkComponentProps) => (
  <span className="block text-center">{props.children}</span>
);

const CurrentYearRenderer = (props: PortableTextMarkComponentProps) => (
  <>{new Date().getFullYear()}</>
);

function SocialLinksRenderer(
  props: PortableTextTypeComponentProps<{
    _type: string;
    name: string;
    noFollow: boolean;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    tiktok?: string;
    linkedIn?: string;
    website?: string;
    youTube?: string;
  }>
): ReactElement {
  return (
    <>
      <SocialLinks
        name={props.value.name}
        noFollow={props.value.noFollow}
        facebook={props.value.facebook}
        instagram={props.value.instagram}
        twitter={props.value.twitter}
        tiktok={props.value.tiktok}
        linkedIn={props.value.linkedIn}
        website={props.value.website}
        youTube={props.value.youTube}
      />
    </>
  );
}

const myComponents = {
  block: {
    normal: ({ children }: { children?: ReactNode }) => (
      <Paragraph>{children}</Paragraph>
    ),
    blockquote: ({ children }: { children?: ReactNode }) => (
      <blockquote>{children}</blockquote>
    ),
    h2: ({ children }: { children?: ReactNode }) => <h2>{children}</h2>,
  },
  list: {
    bullet: BulletListRenderer,
    number: NumberedListRenderer,
  },
  types: {
    faq: FaqRenderer,
    cloudinaryAsset: ImageRenderer,
    'cloudinary.asset': ImageRenderer,
    image: ImageRenderer,
    imageSet: ImageSetRenderer,
    nutritionPanel: NutritionPanelRenderer,
    yotpoGallery: YotpoGalleryRenderer,
    yotpoReviewCarousel: YotpoReviewCarouselRenderer,
    form: ExternalFormRenderer,
    customForm: CustomFormRenderer,
    columns: ColumnsRenderer,
    vimeoVideo: VimeoRenderer,
    recipe: RecipeRenderer,
    aside: AsideRenderer,
    insertAside: AsideRenderer,
    table: TableRenderer,
    message: MessageAsideRenderer,
    warning: WarningAsideRenderer,
    recommendedProduct: RecommendedProductRenderer,
    socialLinks: SocialLinksRenderer,
    carousel: CarouselRenderer,
  },
  marks: {
    link: LinkRenderer,
    internalLink: InternalLinkRenderer,
    onPageLink: OnPageLinkRenderer,
    blogPost: InternalLinkRenderer,
    category: InternalLinkRenderer,
    ambassador: InternalLinkRenderer,
    page: InternalLinkRenderer,
    product: InternalLinkRenderer,
    sub: SubscriptRenderer,
    sup: SuperscriptRenderer,
    center: CenteredRenderer,
    currentYear: CurrentYearRenderer,
  },
};

type ContentTypes = PortableTextBlock | FAQ | Recipe;

interface Props {
  content: ContentTypes | ContentTypes[];
  componentsOverride?: any;
  customClass?: string;
}

export function PortableTextBlockRenderer({
  content,
  componentsOverride,
  customClass,
}: Props) {
  if (!content) return null;

  let hasFigure = false;
  try {
    hasFigure = Array.isArray(content)
      ? !!content.find((b) => b._type === 'image')
      : content._type === 'image';
  } catch (err) {
    console.log(content);
  }

  let hasCarousel = false;
  try {
    hasCarousel = Array.isArray(content)
      ? !!content.find((b) => b._type === 'carousel')
      : content._type === 'carousel';
  } catch (err) {
    console.log(content);
  }

  return (hasFigure || customClass) && !hasCarousel ? (
    <div className={mergeStyles(customClass, `has-[>figure]:clearfix `)}>
      <PortableText
        value={content}
        components={
          componentsOverride !== undefined
            ? mergeDeep({}, myComponents, componentsOverride)
            : myComponents
        }
        onMissingComponent={(message, options) => {
          logBreadcrumb({
            category: 'portableText',
            message: `Unknown portable text renderer`,
            level: 'error',
            data: {
              type: options.type,
              nodeType: options.nodeType,
              message,
            },
          });
          log({
            error: 'Unknown portable text type',
            location: 'PortableText',
          });
        }}
      />
    </div>
  ) : (
    <PortableText
      value={content}
      components={
        componentsOverride !== undefined
          ? mergeDeep({}, myComponents, componentsOverride)
          : myComponents
      }
      onMissingComponent={(message, options) => {
        logBreadcrumb({
          category: 'portableText',
          message: `Unknown portable text renderer`,
          level: 'error',
          data: {
            type: options.type,
            nodeType: options.nodeType,
            message,
          },
        });
        console.log(options);
        log({ error: 'Unknown portable text type', location: 'PortableText' });
      }}
    />
  );
}
