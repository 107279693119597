import { ErrorMessage } from '@hookform/error-message';
import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ChangeEvent, ReactElement, SyntheticEvent, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ErrorCard } from '@components/Alerts';
import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { SuccessAlert } from '@components/Alerts/SuccessAlert';
import { Button, ButtonType } from '@components/Button';
import {
  ImageInput,
  Input,
  Select,
  Textarea,
} from '@components/FormComponents/FormComponents';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useCustomer } from '@hooks/customer/useCustomer';
import {
  CustomForm,
  CustomFormFieldset,
  CustomFormInput,
  CustomFormInputWithOptions,
  isCheckboxInput,
  isImageInput,
  isPortableText,
  isRadioInput,
  isSelectInput,
  isTextareaInput,
} from '@interfaces/IForms';
import { randomiseOrder } from '@lib/randomiseArray';
import { getErrorMessage, log } from '@lib/utils';

import { PortableTextBlockRenderer } from './PortableText';

const SHORTSTACK_DOMAIN = {
  shortStack: 'a.pgtb.me',
  shortStackV2: 'm.cmpgn.page',
} as const;

interface ExternalFormProps {
  _type: string;
  formType: string;
  klaviyoFormId?: string;
  shortStackFormId?: string;
  shortStackV2FormId?: string;
  shortStackFormHeight?: number;
  title?: string;
}

export function ExternalFormRenderer(
  props: PortableTextTypeComponentProps<ExternalFormProps>
): ReactElement {
  if (props.value.formType === 'klaviyo') {
    return <div className={`klaviyo-form-${props.value.klaviyoFormId}`} />;
  }
  if (
    props.value.formType === 'shortStack' ||
    props.value.formType === 'shortStackV2'
  ) {
    return (
      <iframe
        title={props.value.title}
        src={`https://${SHORTSTACK_DOMAIN[props.value.formType]}/${
          props.value.formType === 'shortStack'
            ? props.value.shortStackFormId
            : props.value.shortStackV2FormId
        }?embed=1`}
        width="100%"
        height={`${props.value.shortStackFormHeight}`}
        style={{ border: '0px' }}
      />
    );
  }

  return (
    <ErrorCard
      title="Missing form type"
      message="The form type you have used is not supported here"
    />
  );
}

/** Custom component for selectively rendering the different custom inputs.
 * Used for the portable text rendering of forms but also separate so that
 * custom form fields can be used in specific places, i.e. snowflake pages
 */
function CustomFormInputsRenderer({
  input,
  showOtherInput,
  setShowOtherInput,
  handleImageChange,
  previewUrl,
}: {
  input: CustomFormInput | CustomFormInputWithOptions;
  showOtherInput?: boolean;
  setShowOtherInput?: React.Dispatch<React.SetStateAction<boolean>>;
  handleImageChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  previewUrl?: string | null;
  errors?: any;
}) {
  const handleSelectChange = (e: SyntheticEvent) => {
    if (
      (e.target as HTMLSelectElement).value === 'other' &&
      setShowOtherInput
    ) {
      setShowOtherInput(true);
    }
  };

  if (isSelectInput(input)) {
    if (input.randomiseOptions) randomiseOrder(input.options);
    return (
      <>
        <Select
          {...input}
          {...input.validation}
          onChange={handleSelectChange}
        />
        {showOtherInput && (
          <Input
            key={input._key}
            name={`${input.name}_response`}
            label="Other option:"
            type="text"
            placeholder="Your answer"
            {...input.validation}
          />
        )}
      </>
    );
  } else if (isTextareaInput(input)) {
    return <Textarea {...input} {...input.validation} inlineErrors />;
  } else if (isImageInput(input)) {
    return (
      <ImageInput
        name={input.name}
        label={input.label}
        onChange={handleImageChange}
        previewUrl={previewUrl}
      />
    );
  } else {
    if (
      (isRadioInput(input) || isCheckboxInput(input)) &&
      (input as CustomFormInputWithOptions).randomiseOptions
    )
      randomiseOrder((input as CustomFormInputWithOptions).options);
    return (
      <Input
        {...(input as CustomFormInputWithOptions)}
        {...(input as CustomFormInput).validation}
        inlineErrors
      />
    );
  }
}

// Create a hash of a file to verify what is uploaded to s3
const computeSHA256 = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export function Fieldset({
  fieldset,
  showOtherInput,
  setShowOtherInput,
  handleImageChange,
  previewUrl,
}: {
  fieldset: CustomFormFieldset;
  showOtherInput?: boolean;
  setShowOtherInput?: React.Dispatch<React.SetStateAction<boolean>>;
  handleImageChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  previewUrl?: string | null;
}) {
  return (
    <fieldset>
      {fieldset.title && (
        <legend
          className="my-4 mb-2 inline-block border-b-0 
              border-l-[15px] border-r-0 border-t-0 border-solid border-l-orange bg-grey-dark py-0.5 pl-2.5 
              pr-5 font-secondary text-lg font-normal text-white"
        >
          {fieldset.title}
        </legend>
      )}
      {fieldset.inputs.map((input) =>
        isPortableText(input) ? (
          <PortableTextBlockRenderer key={input._key} content={input.content} />
        ) : (
          CustomFormInputsRenderer({
            input,
            showOtherInput,
            setShowOtherInput,
            handleImageChange,
            previewUrl,
          })
        )
      )}
    </fieldset>
  );
}

export function CustomFormRenderer(
  props: PortableTextTypeComponentProps<CustomForm>
): ReactElement {
  const defaultErrorMessage = (
    <Paragraph>
      Uh oh! Something went wrong trying to save that. Give it another try, and{' '}
      <TextLink href="/contact">get in touch</TextLink> if you continue to have
      issues.
    </Paragraph>
  );

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [success, setSuccess] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const { customer } = useCustomer();

  const formMethods = useForm<any>({
    mode: 'onTouched',
  });

  // Store the file and make a url to show a thumbnail of it
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] ?? null;
    setFile(file);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    } else {
      setPreviewUrl(null);
    }
  };

  // Upload file to S3, returns the file URL if upload successful,
  // otherwise will raise error and return undefined
  const handleFileUpload = async (file: File) => {
    try {
      // Create our file metadata in the bucket, ready for blob upload
      const signedURLResult = await fetch('/api/s3/fileUpload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify({
          fileSize: file.size,
          fileType: file.type,
          checksum: await computeSHA256(file),
        }),
      });
      if (!signedURLResult.ok) {
        const { error } = await signedURLResult.json();
        throw new Error(error.message.join('. '));
      }
      const signedURLResultJSON = await signedURLResult.json();
      if (signedURLResultJSON.error) {
        throw new Error(signedURLResultJSON.error);
      }
      // Extract signed upload url
      const { signedUploadUrl, signedGetUrl, fileName } =
        signedURLResultJSON.data;

      // Now await the upload of the file to S3
      await fetch(signedUploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      return signedGetUrl as string;
    } catch (error) {
      log({ error, location: 'CustomForm handleFileUpload' });
      setSubmitError(true);
      setErrorMessage(
        <Paragraph>
          Uh oh! Something went wrong trying to save that.{' '}
          {getErrorMessage(error)}
        </Paragraph>
      );
      return undefined;
    }
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    setSubmitting(true);
    setSuccess(false);

    // Upload the image (if there is one)
    let imgGetUrl: string | undefined = undefined;
    if (file) {
      imgGetUrl = await handleFileUpload(file);
      if (!imgGetUrl) {
        // If there was an error uploading the image, don't submit the form
        setSubmitting(false);
        return;
      }
    }

    // Save the form data
    const response = await fetch('/api/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        formId: props.value._id,
        formData: { ...data, image: imgGetUrl },
        uniqueEntry: props.value.uniqueEntry,
        emails: props.value.emails,
      }),
    });
    if (response.ok) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);

      // Only reset after a successful submission
      formMethods.reset();
      setFile(null);
      setPreviewUrl(null);
    } else {
      setSubmitError(true);
      const { error } = await response.json();
      if (error.message.includes('23505')) {
        // This is duplicate entry
        setErrorMessage(
          <Paragraph>
            Uh oh! It looks like you have already made a submission for this.
            This form is limited to one submission per person. If you have any
            questions about this, please{' '}
            <TextLink href="/contact">get in touch</TextLink>.
          </Paragraph>
        );
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
    setSubmitting(false);
  };

  return (props.value.loggedInOnly && customer) || !props.value.loggedInOnly ? (
    <div>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          data-form-id={props.value._id}
          className="internalForm mx-auto my-0 flex max-w-[760px] flex-col gap-5"
        >
          {props.value.fieldsets.map((fieldset, idx) => (
            <Fieldset
              key={fieldset._key || `fieldset-${idx}`}
              fieldset={fieldset}
              showOtherInput={showOtherInput}
              setShowOtherInput={setShowOtherInput}
              handleImageChange={handleImageChange}
              previewUrl={previewUrl}
            />
          ))}

          {formMethods.formState.errors &&
            Object.keys(formMethods.formState.errors).length > 0 && (
              <div className="col-span-full flex flex-col">
                {props.value.fieldsets
                  .map((f) => f.inputs)
                  .flat()
                  .map((input) =>
                    isPortableText(input) ? null : (
                      <ErrorMessage
                        key={input._key}
                        errors={formMethods.formState.errors}
                        name={input.name}
                        render={({ message }) => (
                          <span className="text-red before:inline before:content-['⚠_']">
                            {message === 'This field is required'
                              ? `${input.label} is a required field`
                              : message}
                          </span>
                        )}
                      />
                    )
                  )}
              </div>
            )}

          <Button type={ButtonType.submit} disabled={submitting}>
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
          <SuccessAlert
            title=""
            message={`Thanks for your submission!`}
            show={success}
            setShow={setSuccess}
          />
          <ErrorAlert
            show={submitError}
            setShow={setSubmitError}
            title="Oh no!"
          >
            {errorMessage}
          </ErrorAlert>
        </form>
      </FormProvider>
    </div>
  ) : (
    <></>
  );
}
